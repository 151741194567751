<template>
  <FormKit
    :id="id"
    v-model="value"
    type="email"
    :label="label"
    :placeholder="t(placeholder)"
    :validation-messages="{
      required: t(requiredMessage),
      matches: t(matchesMessage),
      email: t(matchesMessage),
    }"
    validation-visibility="blur"
    :validation="validationRules"
    :name="name ?? id"
    label-class="text-md !leading-[19px] font-bold"
    inner-class="relative pt-[5px]"
    outer-class="mb-sm"
    :disabled="disabled"
  >
    <template #label>
      <FormLabel
        v-if="!$slots.label && label"
        :label="label"
        :required="required"
      />
      <slot v-if="$slots.label" name="label" />
    </template>
    <template v-if="info && !disabled" #suffix>
      <Popup
        container-class="form-popup"
        custom-popper-class="form-custom-popper"
        >{{ info }}</Popup
      >
    </template>
  </FormKit>
</template>
<script setup lang="ts">
import Popup from '~/components/components/core/popup/minilu/popup.vue';
import { FormKit } from '@formkit/vue';
import { type FormkitFieldProps } from '~/@types/formie';
import { FormLabel } from '~/complib';

const props = withDefaults(defineProps<FormkitFieldProps>(), {
  label: '',
  id: undefined,
  required: true,
  placeholder: '',
  name: '',
  width: 'w-full',
  requiredMessage: '',
  matchesMessage: '',
  validation: () => [['email']] as [rule: string, ...args: any[]][],
  disabled: false,
  maxlength: 255,
  inputClass: '',
  innerClass: '',
  info: '',
  validationVisibility: 'blur',
  hasWhiteBg: true,
  rounded: false,
});

const value = defineModel<string>();
const { t } = useTrans();

const validationRules = computed<[rule: string, ...args: any[]][]>(() => {
  const validations = [];
  if (props.required) validations.push(['required']);
  if (props.validation && typeof props.validation !== 'string')
    validations.push(...props.validation);
  if (props.validation && typeof props.validation === 'string')
    validations.push([props.validation]);
  return validations as [rule: string, ...args: any[]][];
});
</script>
